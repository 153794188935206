import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const joinApi = createAsyncThunk(
  "joinApi",

  async (userFormData) => {
    console.log(userFormData)
    const data = new FormData();
    data.append("name", userFormData.name);
    data.append("email", userFormData.email);
    data.append("phone", userFormData.phone);
    data.append("education", userFormData.education);
    data.append("description", userFormData.description);
    data.append("file", userFormData.file);
    console.log(data)
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/contact_join/create_join_us`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((result) => {
       
        return result;
      })
      .catch((error) => {
      
        return error;
      });
  }
);

const joinInstrsctorFormSclicer = createSlice({
  name: "form/join-create",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(joinApi.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(joinApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data sent successfully") {
        state.data = action.payload;
        state.error = null;
      } else {
        state.data = null;
        state.error = action.payload;
      }
    });
    builder.addCase(joinApi.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    });
  },
});

export default joinInstrsctorFormSclicer.reducer;

import { createSlice } from "@reduxjs/toolkit";

const TheamSlicer = createSlice({
  name: "theam",
  initialState: {
    them: "dark",
  },
  reducers: {
    setTheam: (state) => {
      const localTheam = localStorage.getItem("them");
      if (localTheam) {
        state.them = localTheam;
      } else {
        localStorage.setItem("them", "dark");
      }
      document
        .getElementsByTagName("body")[0]
        .setAttribute("them-data", state.them);
    },

    ChangeTheam: (state) => {

      state.them = state.them === "light" ? "dark" : "light";
      localStorage.setItem("them", state.them);
      document
        .getElementsByTagName("body")[0]
        .setAttribute("them-data", state.them);
    },
  },
});

export const { ChangeTheam,setTheam } = TheamSlicer.actions;
export default TheamSlicer.reducer;
